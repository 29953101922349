@import "../../../scss/_base.scss";

.side-menu {
	$width: 18.75rem;
	display: block;
	position: fixed;
	height: 100%;
	width: $width;
	z-index: 999;
	top: 0;
	left: -$width;
	background-color: $c-surface-color;
	transition: left .555s ease-in-out;
	padding-top: 5rem;
	padding-left: 1rem;
	overflow-y: auto;
	
	&.display {
		display: block;
		left: 0;
	}

	.contents {
		position: absolute;
		display: block;
		color: $c-accent;
	}

	.side-menu-list {
		margin-bottom: 1rem;
		.side-menu-item {
			text-align: left;
		}
	}

	@include media-down('lg') {
		padding-top: 3.6rem;
	}

	.n-link-container,
	.h-link-container {
		margin-bottom: 0.7rem !important;

		.link {
			color: $c-header-text-color;
			text-decoration: none;

			.svg-icon-wrapper svg {
				fill: $c-header-text-color;
				width: 1.3rem;
				min-width: 1.3rem;
				position: relative;
				bottom: 0.15rem;
			}
		}

		&:hover {
			.link {
				&.sub-link {
					color: $c-text-primary;
				}

				color: $c-botton-color;
				text-decoration: none;

				.svg-icon-wrapper svg {
					fill: $c-botton-color;
				}
			}
		}
	}
}